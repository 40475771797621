import logo from './logo.svg';
import './App.css';

function App() {

  async function postRequest(req, data ='') {

  	// Opciones por defecto estan marcadas con un *
  	const response = await fetch(`http://bserver.yuhmak.com:3111/api/v1/${req}`, {
    	method: 'POST', // *GET, POST, PUT, DELETE, etc.
    	mode: 'cors', // no-cors, *cors, same-origin
    	cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    	credentials: 'same-origin', // include, *same-origin, omit
    	headers: {
    	  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;' // application/json, application/x-www-form-urlencoded, multipart/form-data, or text/plain
    	},
     	redirect: 'follow', // manual, *follow, error
    	referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    	body: data// body data type must match "Content-Type" header
  	});


    console.log(response.headers.get('set-cookie')); 

  	return response.json(); // parses JSON response into native JavaScript objects
}



  postRequest('aaa',JSON.stringify({"data": "MyData"}))
  .then(e=>{
    console.log("==>",e)
  })


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
